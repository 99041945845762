<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "AppPostBlog",
		extends: Base,
		data() {
			return {
				Name: "AppPostBlog",
				mrLevel: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods:{
			changeStatus(k, active="Draft", inactive="Publish"){
				var v = this.data.data[k]
				var text = (v.status=="D"?inactive:active)
				swal({
					title: "Are you sure to "+text+"?",
					text:"",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, "+text+"!"],
					// dangerMode: true,
				}).then((ok) => {
					if (ok) {
						v.status = v.status == "D" ? "P" : "D"
						BOGen.apirest("/"+this.Name, {type:"status",id:v.id,status:v.status},()=>{
							// callback change status
						}, "POST")
					}
	      		});
	    	},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.statusBlog'() {
				this.search()
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-8">
									<h5 class="card-title">{{ObjectName}} List <span
											v-if="data.total">({{ data.total }})</span></h5>
								</div>
								<!-- <div class="col-sm-3">
									<select2 :options="{P:'Publish',D:'Draft'}" v-model="filter.statusBlog">
										<option value="">-- Select Status --</option>
									</select2>
								</div> -->
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control"
												placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="apb_title_id"></SortField>
									</th>
									<th>{{fields.apb_author}}</th>
									<th>{{fields.apb_is_active}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.apb_title_id}}</td>
									<td>{{v.apb_author}}</td>
									<td>
										<StatusLabel type="blog" :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<a href="javascript:;" class="icon_action" @click="changeStatus(k)"
											v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
											v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-9">
							<BoField name="apb_title_id" v-model="row.apb_title_id"></BoField>
							<BoField name="apb_title_en" v-model="row.apb_title_en"></BoField>
							<BoField name="apb_excerpt_id" info="Cuplikan konten pada list blog. Maksimal 200 karakter.">
								<textarea name="apb_excerpt_id" v-model="row.apb_excerpt_id" v-bind="validation('apb_excerpt_id')" class="form-control" maxlength="128"></textarea>
							</BoField>
							<BoField name="apb_excerpt_en" info="Cuplikan konten pada list blog. Maksimal 200 karakter.">
								<textarea name="apb_excerpt_en" v-model="row.apb_excerpt_en" v-bind="validation('apb_excerpt_en')" class="form-control" maxlength="128"></textarea>
							</BoField>
							<BoField name="apb_desc_id">
								<CKEditor name="apb_desc_id" v-model="row.apb_desc_id" v-bind="validation('apb_desc_id')">
								</CKEditor>
							</BoField>
							<BoField name="apb_desc_en">
								<CKEditor name="apb_desc_en" v-model="row.apb_desc_en" v-bind="validation('apb_desc_en')">
								</CKEditor>
							</BoField>
						</div>
						<div class="col-sm-3">
							<!-- <BoField name="apb_image_thumbnail">
								<Uploader name="apb_image_thumbnail" type="blog_thumbnail" :param="{thumbnail:true}"  v-model="row.apb_image_thumbnail">
								</Uploader>
							</BoField> -->
							<BoField name="apb_image_detail">
								<Uploader name="apb_image_detail" type="blog_detail" :param="{thumbnail:true}"  v-model="row.apb_image_detail">
								</Uploader>
							</BoField>
							<BoField name="apb_author" v-model="row.apb_author"></BoField>
							<BoField name="apb_is_active">
								<select2 name="apb_is_active" v-bind="validation('apb_is_active')"
									:options="{D:'Draft',P:'Publish'}" v-model="row.apb_is_active">
									<option value="">-- Select Status --</option>
								</select2>
							</BoField>
							<BoField name="apb_publish_date">
								<DatePicker name="apb_publish_date" v-model="row.apb_publish_date"
									v-bind="validation('apb_publish_date')"></DatePicker>
							</BoField>
						</div>
						<div class="col-sm-6">
							<BoField name="apb_tags_id">
								<TagsInput name="apb_tags_id" v-model="row.apb_tags_id"
									:attr="validation('apb_tags_id')">
								</TagsInput>
							</BoField>
							<BoField name="apb_meta_keyword_id">
								<TagsInput name="apb_meta_keyword_id" v-model="row.apb_meta_keyword_id"
									:attr="validation('apb_meta_keyword_id')"></TagsInput>
							</BoField>
							<BoField name="apb_meta_desc_id">
								<textarea name="apb_excerpt" v-model="row.apb_meta_desc_id" v-bind="validation('apb_meta_desc_id')"
									class="form-control"></textarea>
							</BoField>
						</div>
						<div class="col-sm-6">
							<BoField name="apb_tags_en">
								<TagsInput name="apb_tags_en" v-model="row.apb_tags_en"
									:attr="validation('apb_tags_en')">
								</TagsInput>
							</BoField>
							<BoField name="apb_meta_keyword_en">
								<TagsInput name="apb_meta_keyword_en" v-model="row.apb_meta_keyword_en"
									:attr="validation('apb_meta_keyword_en')"></TagsInput>
							</BoField>
							<BoField name="apb_meta_desc_en">
								<textarea name="apb_excerpt" v-model="row.apb_meta_desc_en" v-bind="validation('apb_meta_desc_en')"
									class="form-control"></textarea>
							</BoField>
						</div>
						<div class="col-xs-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit
									form <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>